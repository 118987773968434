@font-face {
  font-family: "Suiss Regular";
  src: url("/public/fonts/SuisseIntl-Regular.ttf") format("truetype");
}

html {
  -ms-overflow-style: none; /* Internet Explorer and Edge */
  scrollbar-width: none; /* Firefox */
  scroll-behavior: smooth;
}

html::-webkit-scrollbar {
  display: none; /* Chrome, Safari, and Opera */
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: scroll; /* Ensures the scroll functionality is enabled */
  scroll-behavior: smooth;

  background-color: #fffef3 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Hides the scrollbar but allows scrolling in WebKit browsers */
body::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari, and newer versions of Edge */
}
